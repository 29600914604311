import axios from 'axios';

const getDefaultState = () => {
    return {
        userJourneys: [],
        lastUpdated: null,
    }
};

const prepareJourneyStructureForSave = async function (JourneyID, Structure) {

    let contentNewImages = [];

    let journeyLinkedAssets = [];

    Structure.forEach((StructureItem) => {
        // check chapters for new images
        if (StructureItem.Type === 'Chapter') {

            if (StructureItem.hasOwnProperty('Units')) {

                //before save always eliminate the dummy last unit
                if (StructureItem.Units.length > 1) {
                    StructureItem.Units = StructureItem.Units.filter((unit) => !unit.hasOwnProperty('Temp'));
                }

                StructureItem.Units.forEach((Unit, indexUnit) => {

                    if (Unit.hasOwnProperty('LinkedAssets')) {

                        Unit.LinkedAssets.forEach((linkedAsset, indexLinkedAsset) => {
                            if (linkedAsset.Type === 'Attachment' && linkedAsset.hasOwnProperty('IsNew')) {

                                let fileObject = {
                                    name: linkedAsset.Name,
                                    ext: linkedAsset.Ext,
                                    type: linkedAsset.File_type,
                                    file: linkedAsset.File,
                                    folder_name: `journey/${JourneyID}/assets`,
                                }

                                Unit.LinkedAssets[indexLinkedAsset] = {
                                    Type: linkedAsset.Type,
                                    Name: `${linkedAsset.Name}.${linkedAsset.Ext}`,
                                    Label: linkedAsset.Label,
                                    IsTreasureChestAsset: linkedAsset.IsTreasureChestAsset,
                                    FolderName: `journey/${JourneyID}/assets`
                                }

                                journeyLinkedAssets.push(fileObject);
                            }
                            if (linkedAsset.Type === 'MemoryCard') {
                                let memoryCardSaveObject = {
                                    Type: linkedAsset.Type,
                                    BatchNumber: linkedAsset.BatchNumber,
                                    MemoryCardNumber: linkedAsset.MemoryCardNumber,
                                    IsTreasureChestAsset: linkedAsset.IsTreasureChestAsset,
                                }
                                Unit.LinkedAssets[indexLinkedAsset] = memoryCardSaveObject;
                            }
                            if (linkedAsset.Type === 'ExpeditionLog') {
                                let expeditionLogSaveObject = {
                                    Type: linkedAsset.Type,
                                    ExpeditionLogNumber: linkedAsset.ExpeditionLogNumber,
                                    IsTreasureChestAsset: linkedAsset.IsTreasureChestAsset,
                                }
                                Unit.LinkedAssets[indexLinkedAsset] = expeditionLogSaveObject;
                            }
                        });
                    }

                    if (Unit.hasOwnProperty('Blocks')) {
                        Unit.Blocks.forEach((Block) => {
                            if (Block.hasOwnProperty('Elements')) {
                                Block.Elements.forEach((Element) => {
                                    if (Element.type === 'image' && Element.data.file.hasOwnProperty('isNew')) {

                                        let imageObject = {
                                            element_type: 'image',
                                            name: Element.data.file.name,
                                            ext: Element.data.file.ext,
                                            type: Element.data.file.type,
                                            file: Element.data.file.file,
                                            folder_name: `journey/${JourneyID}/assets`,
                                        }

                                        const imageName = `${Element.data.file.name}.${Element.data.file.ext}`;

                                        Element.data.file = {
                                            name: imageName
                                        }
                                        contentNewImages.push(imageObject);
                                    }

                                    if (Element.type === 'image' && !Element.data.file.hasOwnProperty('isNew') && Element.data.file.hasOwnProperty('url')) {
                                        delete Element.data.file.url;
                                    }

                                    if (Element.type === 'reusableImage' && Element.data.file.hasOwnProperty('isNew')) {

                                        let reusableImageObject = {
                                            element_type: 'reusableImage',
                                            source_key: Element.data.file.source_key,
                                            file_name: Element.data.file.name,
                                            folder_name: `journey/${JourneyID}/assets`,
                                        }

                                        Element.data.file = {
                                            name: reusableImageObject.file_name
                                        }
                                        contentNewImages.push(reusableImageObject);
                                    }

                                    if (Element.type === 'reusableImage' && !Element.data.file.hasOwnProperty('isNew') && Element.data.file.hasOwnProperty('url')) {
                                        delete Element.data.file.url;
                                    }

                                });
                            }
                        });
                    }
                });
            }
        }

        // convert periods to universal time
        // check events for modules with unlock time
        if (StructureItem.Type === 'Event') {

            if (StructureItem.Options.IsUnlock && StructureItem.Periods.Unlock !== null) {
                StructureItem.Periods.Unlock = new Date(StructureItem.Periods.Unlock).toISOString();
            }

            if (StructureItem.Options.IsMeeting && StructureItem.Periods.From !== null) {
                StructureItem.Periods.From = new Date(StructureItem.Periods.From).toISOString();
            }

            if (StructureItem.Options.IsMeeting && StructureItem.Periods.To !== null) {
                StructureItem.Periods.To = new Date(StructureItem.Periods.To).toISOString();
            }

            if (StructureItem.hasOwnProperty('LinkedAssets')) {

                StructureItem.LinkedAssets.forEach((linkedAsset, indexLinkedAsset) => {

                    if (linkedAsset.Type === 'Attachment' && linkedAsset.hasOwnProperty('IsNew')) {

                        let fileObject = {
                            name: linkedAsset.Name,
                            ext: linkedAsset.Ext,
                            type: linkedAsset.File_type,
                            file: linkedAsset.File,
                            folder_name: `journey/${JourneyID}/assets`,
                        }

                        StructureItem.LinkedAssets[indexLinkedAsset] = {
                            Type: linkedAsset.Type,
                            Name: `${linkedAsset.Name}.${linkedAsset.Ext}`,
                            Label: linkedAsset.Label,
                            IsTreasureChestAsset: linkedAsset.IsTreasureChestAsset,
                            FolderName: `journey/${JourneyID}/assets`
                        }

                        journeyLinkedAssets.push(fileObject);
                    }

                    if (linkedAsset.Type === 'MemoryCard') {
                        let memoryCardSaveObject = {
                            Type: linkedAsset.Type,
                            BatchNumber: linkedAsset.BatchNumber,
                            MemoryCardNumber: linkedAsset.MemoryCardNumber,
                            IsTreasureChestAsset: linkedAsset.IsTreasureChestAsset,
                        }
                        StructureItem.LinkedAssets[indexLinkedAsset] = memoryCardSaveObject;
                    }
                    if (linkedAsset.Type === 'ExpeditionLog') {
                        let expeditionLogSaveObject = {
                            Type: linkedAsset.Type,
                            ExpeditionLogNumber: linkedAsset.ExpeditionLogNumber,
                            IsTreasureChestAsset: linkedAsset.IsTreasureChestAsset,
                        }
                        StructureItem.LinkedAssets[indexLinkedAsset] = expeditionLogSaveObject;
                    }
                });
            }

            if (StructureItem.hasOwnProperty('Details') && StructureItem.Details.hasOwnProperty('Elements') && StructureItem.Details.Elements.length > 0) {
                StructureItem.Details.Elements.forEach((Element) => {
                    if (Element.type === 'image' && Element.data.file.hasOwnProperty('isNew')) {

                        let imageObject = {
                            element_type: 'image',
                            name: Element.data.file.name,
                            ext: Element.data.file.ext,
                            type: Element.data.file.type,
                            file: Element.data.file.file,
                            folder_name: `journey/${JourneyID}/assets`,
                        }

                        const imageName = `${Element.data.file.name}.${Element.data.file.ext}`;

                        Element.data.file = {
                            name: imageName
                        }
                        contentNewImages.push(imageObject);
                    }

                    if (Element.type === 'image' && !Element.data.file.hasOwnProperty('isNew') && Element.data.file.hasOwnProperty('url')) {
                        delete Element.data.file.url;
                    }

                    if (Element.type === 'reusableImage' && Element.data.file.hasOwnProperty('isNew')) {

                        let reusableImageObject = {
                            element_type: 'reusableImage',
                            source_key: Element.data.file.source_key,
                            file_name: Element.data.file.name,
                            folder_name: `journey/${JourneyID}/assets`,
                        }

                        Element.data.file = {
                            name: reusableImageObject.file_name
                        }
                        contentNewImages.push(reusableImageObject);
                    }

                    if (Element.type === 'reusableImage' && !Element.data.file.hasOwnProperty('isNew') && Element.data.file.hasOwnProperty('url')) {
                        delete Element.data.file.url;
                    }

                });
            }

            if (StructureItem.hasOwnProperty('Assignment')) {

                if (StructureItem.Assignment.hasOwnProperty('ID') && StructureItem.Assignment.ID !== null) {

                    // save images and reusable images from checklist task
                    if (StructureItem.Assignment.Type === 'Checklist') {

                        StructureItem.Assignment.Content.Tasks.forEach((Task) => {

                             if (Task.hasOwnProperty('Content') && Task.Content.hasOwnProperty('Elements') && Task.Content.Elements.length > 0) {

                                 Task.Content.Elements.forEach((TaskElement) => {

                                     if (TaskElement.type === 'image' && TaskElement.data.file.hasOwnProperty('isNew')) {

                                         let imageObject = {
                                             element_type: 'image',
                                             name: TaskElement.data.file.name,
                                             ext: TaskElement.data.file.ext,
                                             type: TaskElement.data.file.type,
                                             file: TaskElement.data.file.file,
                                             folder_name: `journey/${JourneyID}/assets`,
                                         }

                                         const imageName = `${TaskElement.data.file.name}.${TaskElement.data.file.ext}`;

                                         TaskElement.data.file = {
                                             name: imageName
                                         }
                                         contentNewImages.push(imageObject);
                                     }

                                     if (TaskElement.type === 'image' && !TaskElement.data.file.hasOwnProperty('isNew') && TaskElement.data.file.hasOwnProperty('url')) {
                                         delete TaskElement.data.file.url;
                                     }

                                     if (TaskElement.type === 'reusableImage' && TaskElement.data.file.hasOwnProperty('isNew')) {

                                         let reusableImageObject = {
                                             element_type: 'reusableImage',
                                             source_key: TaskElement.data.file.source_key,
                                             file_name: TaskElement.data.file.name,
                                             folder_name: `journey/${JourneyID}/assets`,
                                         }

                                         TaskElement.data.file = {
                                             name: reusableImageObject.file_name
                                         }
                                         contentNewImages.push(reusableImageObject);
                                     }

                                     if (TaskElement.type === 'reusableImage' && !TaskElement.data.file.hasOwnProperty('isNew') && TaskElement.data.file.hasOwnProperty('url')) {
                                         delete TaskElement.data.file.url;
                                     }

                                 });
                            }
                        });

                    }

                }

            }

        }

    });

    return {
        contentNewImages: contentNewImages,
        journeyLinkedAssets: journeyLinkedAssets,
    };
};

const checkOverviewForNewImages = async function(JourneyID, Overview) {

    let overviewNewImages = [];

    Overview.Elements.forEach((Element) => {

        if (Element.type === 'image' && Element.data.file.hasOwnProperty('isNew')) {

            let imageObject = {
                element_type: 'image',
                name: Element.data.file.name,
                ext: Element.data.file.ext,
                type: Element.data.file.type,
                file: Element.data.file.file,
                folder_name: `journey/${JourneyID}/assets`,
            }

            const imageName = `${Element.data.file.name}.${Element.data.file.ext}`;

            Element.data.file = {
                name: imageName
            }
            overviewNewImages.push(imageObject);
        }

        if (Element.type === 'image' && !Element.data.file.hasOwnProperty('isNew') && Element.data.file.hasOwnProperty('url')) {
            delete Element.data.file.url;
        }

        if (Element.type === 'reusableImage' && Element.data.file.hasOwnProperty('isNew')) {

            let reusableImageObject = {
                element_type: 'reusableImage',
                source_key: Element.data.file.source_key,
                file_name: Element.data.file.name,
                folder_name: `journey/${JourneyID}/assets`,
            }

            Element.data.file = {
                name: reusableImageObject.file_name
            }
            overviewNewImages.push(reusableImageObject);
        }

        if (Element.type === 'reusableImage' && !Element.data.file.hasOwnProperty('isNew') && Element.data.file.hasOwnProperty('url')) {
            delete Element.data.file.url;
        }
    })

    return overviewNewImages;

};

const state = getDefaultState();

const getters = {
    getUserJourneys: state => state.userJourneys,
    getUserAuthorJourneys: state => {
        return state.userJourneys.filter((userJourney) => userJourney.IsCurrentUserAuthor);
    },
    getLastUpdated: state => state.lastUpdated,
};

const actions = {
    async list({ commit, dispatch, rootState}) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };
            let payload = {

            };

            const response = await axios.post('/v2/journey/list', payload, config);

            return response.data.journeysList;
        }
        catch (err) {
            // throw new Error(err);
        }
    },

    async listUserJourneys({ commit, dispatch, state, rootState }) {
      try {
        commit('SET_LAST_UPDATED', null); // Reset last updated timestamp
        
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;
        const config = {
          headers: {
            Authorization: rootState.auth.bearer,
          },
        };
        
        const response = await axios.post('/v2/journey/list-user-journeys', {}, config);
        commit('SET_USER_JOURNEYS', response.data.journeysList);
        commit('SET_LAST_UPDATED', new Date());
        return response.data.journeysList;
      } catch (err) {
        console.error('Error fetching user journeys:', err);
        throw err;
      }
    },

    async listAllJourneysNames({ commit, dispatch, rootState}) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };
            let payload = {

            };

            const response = await axios.post('/v2/journey/list-all-journeys-names', payload, config);

            return response.data.journeysNames;

        }
        catch (err) {
            // throw new Error(err);
        }
    },

    async create({ commit, dispatch, rootState }, newJourney) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let journeyPayload = {
                "Name": newJourney.Name,
                "Description": newJourney.Description,
                "CategoryID": newJourney.CategoryID,
                "Picture": null,
                "Overview": newJourney.Overview,
                "Structure": newJourney.Structure
            }

            let isPictureUpdated = (newJourney.hasOwnProperty('PictureUpdated'));

            if (isPictureUpdated) {
                journeyPayload.Picture =`${newJourney.PictureUpdated.name}.${newJourney.PictureUpdated.ext}`;
            }

            // save overview images to S3
            let overviewNewImages = await checkOverviewForNewImages(null, newJourney.Overview);

            // // need to call it before so the base 64 data gets deleted from payload object
            // let contentNewImages = await checkChaptersForNewImages(null, newJourney.Chapters);

            const response = await axios.post('/v2/journey/create', journeyPayload, config);

            let newCreatedJourney = response.data.newJourney;

            if (overviewNewImages.length > 0) {
                for (const overviewNewImage of overviewNewImages) {
                    overviewNewImage.folder_name = `journey/${newCreatedJourney.ID}/assets`;
                    if (overviewNewImage.element_type === 'image') {
                        await dispatch('file/uploadFile', overviewNewImage, {root: true});
                    }
                    if (overviewNewImage.element_type === 'reusableImage') {
                        await dispatch('file/copyFileBetweenS3Folders', overviewNewImage, {root: true});
                    }
                }
            }

            // // save blocks images to S3, after save was finished, now we have a Journey ID, so we need to add it to the S3 folder path
            // if (contentNewImages.length > 0) {
            //     for (const contentNewImage of contentNewImages) {
            //         contentNewImage.folder_name = `journey/${newCreatedJourney.ID}/assets`;
            //         await dispatch('file/uploadFile', contentNewImage, {root: true});
            //     }
            // }

            if (isPictureUpdated) {
                let journeyPicturePayload = newJourney.PictureUpdated;
                journeyPicturePayload.folder_name = `journey/${newCreatedJourney.ID}`;
                await dispatch('file/uploadFile', journeyPicturePayload, {root: true});
                newCreatedJourney.PictureURL = await dispatch('file/getFile', {
                    file_name: newCreatedJourney.Picture,
                    folder_name: `journey/${newCreatedJourney.ID}`
                }, {root: true});
            }

            newCreatedJourney.IsCurrentUserAuthor = true;
            newCreatedJourney.IsCurrentUserParticipant = false;

            commit('ADD_JOURNEY', newCreatedJourney);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey successfully created'
            }, {root: true});

            return newCreatedJourney;

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey failed to be created'
            }, {root: true});
            throw new Error(err);
        }
    },

    async copyJourney({ commit, dispatch, rootState }, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            commit('loading/setLoading', true, { root: true });

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            const response = await axios.post('/v2/journey/copy-journey', payload, config);

            let newCreatedJourney = response.data.newJourney;

            // newCreatedJourney.IsCurrentUserAuthor = true;
            // newCreatedJourney.IsCurrentUserParticipant = false;

            // commit('ADD_JOURNEY', newCreatedJourney);

            commit('loading/setLoading', false, { root: true });

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey successfully created'
            }, {root: true});

            return newCreatedJourney;

        }
        catch (err) {
            commit('loading/setLoading', false, { root: true });

            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey failed to be created'
            }, {root: true});
            throw new Error(err);
        }
    },

    async update({ commit, dispatch, rootState }, updatedJourney) {
        commit('loading/setLoading', true, { root: true });

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            // save overview images to S3
            let overviewNewImages = await checkOverviewForNewImages(updatedJourney.ID, updatedJourney.Overview);

            if (overviewNewImages.length > 0) {
                for (const overviewNewImage of overviewNewImages) {
                    if (overviewNewImage.element_type === 'image') {
                        await dispatch('file/uploadFile', overviewNewImage, {root: true});
                    }
                    if (overviewNewImage.element_type === 'reusableImage') {
                        await dispatch('file/copyFileBetweenS3Folders', overviewNewImage, {root: true});
                    }
                }
            }

            // save blocks images to S3
            let {contentNewImages, journeyLinkedAssets} = await prepareJourneyStructureForSave(updatedJourney.ID, updatedJourney.Structure);

            if (contentNewImages.length > 0) {
                for (const contentNewImage of contentNewImages) {
                    if (contentNewImage.element_type === 'image') {
                        await dispatch('file/uploadFile', contentNewImage, {root: true});
                    }
                    if (contentNewImage.element_type === 'reusableImage') {
                        await dispatch('file/copyFileBetweenS3Folders', contentNewImage, {root: true});
                    }
                }
            }

            if (journeyLinkedAssets.length > 0) {
                for (const unitAsset of journeyLinkedAssets) {
                    await dispatch('file/uploadFile', unitAsset, {root: true});
                }
            }

            let journeyPayload = {
                "AuthorID": updatedJourney.AuthorID,
                "Name": updatedJourney.Name,
                "Description": updatedJourney.Description,
                "CategoryID": updatedJourney.CategoryID,
                "Picture": updatedJourney.Picture,
                "Overview": updatedJourney.Overview,
                "Structure": updatedJourney.Structure,
            };

            if (updatedJourney.hasOwnProperty('ExistingAssetsToDelete') && updatedJourney.ExistingAssetsToDelete.length > 0) {
                journeyPayload.ExistingAssetsToDelete = updatedJourney.ExistingAssetsToDelete;

                for (let ExistingAssetToDelete of updatedJourney.ExistingAssetsToDelete) {
                    let payloadS3 = {
                        folder_name: `journey/${updatedJourney.ID}/assets`,
                        file_name: ExistingAssetToDelete
                    }
                    await dispatch('file/deleteFile', payloadS3, {root: true});
                }
            }

            let isPictureUpdated = (updatedJourney.hasOwnProperty('PictureUpdated'));
            let picture_old = updatedJourney.Picture;

            if (isPictureUpdated) {
                picture_old = updatedJourney.Picture;
                journeyPayload.Picture =`${updatedJourney.PictureUpdated.name}.${updatedJourney.PictureUpdated.ext}`;
            }

            // save updated Journey
            const response = await axios.put(`/v2/journey/${updatedJourney.ID}`, journeyPayload, config);

            // save Journey main Picture if updated
            if (isPictureUpdated) {
                let journeyPicturePayload = updatedJourney.PictureUpdated;
                journeyPicturePayload.folder_name = `journey/${updatedJourney.ID}`;
                await dispatch('file/uploadFile', journeyPicturePayload, {root: true});
                // get main Picture download pre-signed url
                response.data.updatedJourney.PictureURL = await dispatch('file/getFile', {
                    file_name: response.data.updatedJourney.Picture,
                    folder_name: `journey/${response.data.updatedJourney.ID}`
                }, {root: true});

                // delete Journey old main Picture
                if (picture_old !== null) {
                    const delete_payload = {
                        folder_name: `journey/${updatedJourney.ID}`,
                        file_name: picture_old
                    }
                    await dispatch('file/deleteFile', delete_payload, {root: true});
                }
            }

            response.data.updatedJourney.IsCurrentUserAuthor = updatedJourney.IsCurrentUserAuthor;
            response.data.updatedJourney.IsCurrentUserParticipant = updatedJourney.IsCurrentUserParticipant;

            commit('UPDATE_JOURNEY', response.data.updatedJourney);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey successfully updated'
            }, {root: true});

            commit('loading/setLoading', false, { root: true });

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey failed to be updated'
            }, {root: true});

            commit('loading/setLoading', false, { root: true });

            throw new Error(err);
        }
    },

    async updateActiveStatus({ commit, dispatch, rootState }, updatedJourney) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payload = {
                "Active": !updatedJourney.Active,
            }

            const response = await axios.put(`/v2/journey/update-active-status/${updatedJourney.ID}`, payload, config);

            commit('UPDATE_JOURNEY_ACTIVE_STATUS', response.data.updatedJourney);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey status successfully updated'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey status failed to be updated'
            }, {root: true});
            throw new Error(err);
        }
    },

    async updateAuthor({ commit, dispatch, rootState }, updatedJourney) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payloadAPI = {
                "JourneyID": updatedJourney.JourneyID,
                "NewAuthorID": updatedJourney.NewAuthorID,
                "OldAuthorID": updatedJourney.OldAuthorID,
            }

            const response = await axios.put(`/v2/journey/update-author/${updatedJourney.JourneyID}`, payloadAPI, config);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey author successfully updated'
            }, {root: true});

            return response.data.updatedJourney;
        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey author failed to be updated'
            }, {root: true});
            throw new Error(err);
        }
    },

    async updateParticipantProgress({ commit, dispatch, rootState }, updatedProgressData) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payload = {
                "JourneyID": updatedProgressData.JourneyID,
                "StructureItemID": updatedProgressData.StructureItemID,
            }

            const response = await axios.put(`/v2/journey/update-participant-progress`, payload, config);

            commit('UPDATE_JOURNEY_PROGRESS', response.data.updatedProgress);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'CONGRATULATIONS on your progress!'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Failed to update your progress'
            }, {root: true});
            throw new Error(err);
        }
    },

    async updateJourneyUserConsent({ commit, dispatch, rootState }, data) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payload = {
                "JourneyParticipantLinkID": data.JourneyParticipantLinkID,
            }

            await axios.post(`/v2/journey/update-journey-user-consent`, payload, config);

            commit('UPDATE_JOURNEY_USER_CONSENT', data.JourneyID);

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Failed to update your consent'
            }, {root: true});
            throw new Error(err);
        }
    },

    async deleteJourney({ commit, dispatch, rootState }, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payloadAPI = {
                "JourneyID": payload.JourneyID,
            }

            await axios.delete(`/v2/journey-delete/${payloadAPI.JourneyID}`, config);

            commit('DELETE_JOURNEY', payloadAPI.JourneyID);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey deleted successfully'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Failed to delete the journey'
            }, {root: true});
            throw new Error(err);
        }
    },

    async getUsersLinkedToJourney({ commit, dispatch, rootState }, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;
        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {
                JourneyID: payload.JourneyID,
                IsFacilitatorView: payload.IsFacilitatorView,
            };

            const response = await axios.post('/v2/get-users-linked-to-journey', payloadAPI, config);

            response.data.linkedUsers.forEach((user) => {
                user.Exists = true;
            });

            return response.data.linkedUsers;
        }
        catch (err) {
            throw new Error(err);
        }
    },

    async addUserToJourney({ commit, dispatch, rootState }, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        let formattedNewUsers = [];

        if (payload.NewUsers.length > 0) {
            payload.NewUsers.forEach((newUser) => {
                let formattedNewUser = {
                    CognitoAttributes: {
                        given_name: newUser.given_name || '',
                        family_name: newUser.family_name || '',
                        email: newUser.email,
                        address: newUser.address || '',
                        'custom:position': newUser['custom:position'] || '',
                    },
                    Note: newUser.Note || '',
                    JourneyRole: newUser.JourneyRole
                }

                formattedNewUsers.push(formattedNewUser);
            })
        }

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payloadAPI = {
                "NewUsers": formattedNewUsers,
                "JourneyID": payload.JourneyID,
                "IsAlignExpirationDateToYearLongActive": payload.IsAlignExpirationDateToYearLongActive,
                "SkipLinkUserToJourney": payload.SkipLinkUserToJourney,
            };

            await axios.post('/v2/add-user-to-journey', payloadAPI, config);

            if (payload.JourneyRole === 'Participant' && !payload.SkipLinkUserToJourney) {

                let updatedDraftUsers = payload.AllDraftUsers.filter(obj1 =>
                    !payload.NewUsers.some(obj2 => obj1.tempID === obj2.tempID)
                );

                let payloadAPI2 = {
                    JourneyID: payload.JourneyID,
                    DraftUsers: updatedDraftUsers,
                };

                await dispatch('updateDraftUsersToJourney', payloadAPI2);

            }

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Users successfully added to journey'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Some Participants failed to be registered'
            }, {root: true});
            throw new Error(err);
        }
    },

    async removeUsersFromJourney({commit, dispatch, rootState}, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payloadAPI = {
                "RemovedUsersParticipantLinkIDs": payload.RemovedUsersParticipantLinkIDs,
            };

            await axios.post('/v2/remove-users-from-journey', payloadAPI, config);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Users removed successfully'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Failed to remove users from journey'
            }, {root: true});
            throw new Error(err);
        }
    },

    async updateParticipantByAuthor({commit, dispatch, rootState}, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payloadAPI = {
                JourneyParticipantLinkID: payload.JourneyParticipantLinkID,
                JourneyID: payload.JourneyID,
                JourneyRole: payload.JourneyRole,
                Note: payload.Note,
                ExtraPermissions: payload.ExtraPermissions,
                sub: payload.sub,
                given_name: payload.given_name,
                family_name: payload.family_name,
                address: payload.address,
                'custom:position': payload['custom:position'],
            };

            await axios.post('/v2/update-participant-by-author', payloadAPI, config);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'User successfully updated'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Failed to update user'
            }, {root: true});
            throw new Error(err);
        }
    },

    async getJourneysImages({commit, dispatch, rootState}) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payloadAPI = {

            };

            const response = await axios.post('/v2/list-journeys-images', payloadAPI, config);

            return response.data.assetsKeys;

        }
        catch (err) {
            throw new Error(err);
        }
    },

    async getUserJourneyProgress({commit, dispatch, state, rootState}, journeyID) {
        const journey = state.userJourneys.find((userJourney) => userJourney.ID === journeyID);

        if (journey.hasOwnProperty('ParticipantProgress')) {
            return {
                ParticipantProgress: journey.ParticipantProgress,
                ParticipantProgressPercentage: journey.ParticipantProgressPercentage,
            }
        }
        return null;
    },

    async submitAssignment({commit, dispatch, state, rootState}, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payloadAPI = {
                SubmittedStructureItemID: payload.SubmittedStructureItemID,
                Journey: payload.Journey,
            };

            let response = await axios.post('/v2/journey/submit-assignment', payloadAPI, config);

            let updatedJourney = response.data.journey;

            if (!payload.IsJourneyReusableTemplatePage) {
                commit('UPDATE_USER_JOURNEY', {
                    Journey: updatedJourney
                });

                dispatch('notifications/addNotification', {
                    type: 'success',
                    message: 'Assignment submitted successfully'
                }, {root: true});

                dispatch('userNotifications/fetchUserNotifications', {

                },{root: true});

                const indexUserJourney = state.userJourneys.findIndex((userJourney) => userJourney.ID === payload.Journey.ID);

                return state.userJourneys[indexUserJourney];
            }
            else {
                return updatedJourney;
            }
        }
        catch (err){
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Assignment failed to be submitted'
            }, {root: true});
        }
    },
    async saveAssignmentResponse({commit, dispatch, state, rootState}, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payloadAPI = {
                SubmittedStructureItemID: payload.SubmittedStructureItemID,
                Journey: payload.Journey,
            };

            let response = await axios.post('/v2/journey/save-assignment-response', payloadAPI, config);

            let updatedJourney = response.data.journey;

            commit('UPDATE_USER_JOURNEY', {
                Journey: updatedJourney
            });

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Assignment saved successfully'
            }, {root: true});

            const indexUserJourney = state.userJourneys.findIndex((userJourney) => userJourney.ID === payload.Journey.ID);

            return state.userJourneys[indexUserJourney];
        }
        catch (err){
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Assignment failed to be saved'
            }, {root: true});
        }
    },

    async updateDraftUsersToJourney ({commit, dispatch, state, rootState}, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            payload.DraftUsers.forEach((user) => {
                if (user.hasOwnProperty('IsSelected')) {
                    delete user.IsSelected;
                }
            });

            let payloadAPI = {
                JourneyID: payload.JourneyID,
                DraftUsers: payload.DraftUsers,
            };

            await axios.post('/v2/journey/update-draft-users-to-journey', payloadAPI, config);

            commit('UPDATE_DRAFT_USERS', payloadAPI);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey draft users successfully updated'
            }, {root: true});
        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey draft users failed to be updated'
            }, {root: true});
        }
    },

    resetState({ commit }) {
        commit('RESET_STATE');
    },
}

const mutations = {
    SET_USER_JOURNEYS(state, journeys) {
        state.userJourneys = journeys;
    },
    SET_LAST_UPDATED(state, timestamp) {
        state.lastUpdated = timestamp;
    },
    ADD_JOURNEY(state, newJourney) {
        state.userJourneys.push(newJourney);
    },
    UPDATE_JOURNEY(state, updatedJourney) {
        const indexUserJourney = state.userJourneys.findIndex(journey => journey.ID === updatedJourney.ID);

        if (indexUserJourney !== -1) {
            for (let key in updatedJourney) {
                if (key in state.userJourneys[indexUserJourney]) {
                    state.userJourneys[indexUserJourney][key] = updatedJourney[key];
                }
            }
        }
    },
    UPDATE_JOURNEY_ACTIVE_STATUS(state, updatedJourney) {
        const indexUserJourneys = state.userJourneys.findIndex(journey => journey.ID === updatedJourney.ID);

        if (indexUserJourneys !== -1) {
            state.userJourneys[indexUserJourneys].Active = updatedJourney.Active;
        }
    },
    UPDATE_JOURNEY_PROGRESS(state, updatedProgress) {
        const indexUserJourneys = state.userJourneys.findIndex(journey => journey.ID === updatedProgress.JourneyID);

        if (indexUserJourneys !== -1) {
            state.userJourneys[indexUserJourneys].ParticipantProgress = updatedProgress.ParticipantProgress;
            state.userJourneys[indexUserJourneys].ParticipantProgressPercentage = updatedProgress.ParticipantProgressPercentage;
        }
    },
    UPDATE_JOURNEY_USER_CONSENT(state, JourneyID) {
        const indexUserJourneys = state.userJourneys.findIndex(journey => journey.ID === JourneyID);

        if (indexUserJourneys !== -1) {
            state.userJourneys[indexUserJourneys].Consent = true;
        }
    },
    UPDATE_USER_JOURNEY(state, payload) {
        const indexUserJourney = state.userJourneys.findIndex((userJourney) => userJourney.ID === payload.Journey.ID);

        if (indexUserJourney !== -1) {
            state.userJourneys[indexUserJourney] = payload.Journey;
        }
    },
    DELETE_JOURNEY(state, deletedJourneyID) {
        state.userJourneys = state.userJourneys.filter((userJourney) => userJourney.ID !== deletedJourneyID);
    },
    SET_PICTURE(state, picture) {
        // state.userInfo.picture = picture;
        // state.user.attributes.picture = picture;
    },
    UPDATE_PARTICIPANT_ASSIGNMENT(state, payload) {
        const indexUserJourneys = state.userJourneys.findIndex(journey => journey.ID === payload.JourneyID);
        if (indexUserJourneys !== -1
        && state.userJourneys[indexUserJourneys].Structure[payload.indexStructureItem] !== undefined
        && state.userJourneys[indexUserJourneys].Structure[payload.indexStructureItem].Tasks[payload.indexTask] !== undefined) {
            state.userJourneys[indexUserJourneys].Structure[payload.indexStructureItem].Tasks[payload.indexTask].IsDone = payload.Task.IsDone;
        }
    },
    UPDATE_DRAFT_USERS(state, payload) {
        const indexUserJourneys = state.userJourneys.findIndex(journey => journey.ID === payload.JourneyID);
        if (indexUserJourneys !== -1) {
            state.userJourneys[indexUserJourneys].DraftUsers = payload.DraftUsers;
        }
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState());
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
